<template>
  <div class="main-body">
    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="标题">
          <a-input v-model:value="formState.title" placeholder="标题" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table rowKey="level_id" :columns="columns" :data-source="data" :pagination="Refpagination" @change="handleRefresh">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateUserLevelModal(record)">查看</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
  </div>
  <update-user-level ref="RefUpdateUserLevel" @handleSubmit="handleRefresh" />
</template>
<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, reactive, ref, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import * as Api from "@/views/message/api";
import { useRouter } from "vue-router";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    width: "10%",
  },
  {
    title: "标题",
    dataIndex: "title",
    width: "12%",
  },
  {
    title: "姓名",
    dataIndex: "username",
    width: "10%",
  },
  {
    title: "电话",
    dataIndex: "phone",
    width: "10%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {
  },
  setup() {
    const formState = reactive({
      level_name: "",
      grade_id: 0,
      betweenTime: "",
    });

    const gradeList = ref([{ name: "默认", grade_id: 0 }]);

    const data = ref([]);
    const Refpagination = ref({});

    // 添加用户等级
    const RefCreateUserLevel = ref();
    const showCreateUserLevelModal = () => {
      RefCreateUserLevel.value.showFromModal();
    };

	const router = useRouter();
    // 查看
    const RefUpdateUserLevel = ref();
    const showUpdateUserLevelModal = (item) => {
      //RefUpdateUserLevel.value.showFromModal(item);
router.push({
path: "/message/read",
query: { id: item.id },
});
    };

    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.list(formState).then((res) => {
        Refpagination.value.current = res.data.current_page;
        Refpagination.value.pageSize = res.data.per_page;
        Refpagination.value.total = res.data.total;
        data.value = res.data.data;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.title + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.del({ id: record.id }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
    });

    return {
      value1: ref(),
      formState,
      gradeList,
      columns,
      data,
      Refpagination,
      RefCreateUserLevel,
      RefUpdateUserLevel,
      showCreateUserLevelModal,
      showUpdateUserLevelModal,
      showDeleteConfirm,
      handleRefresh,
    };
  },
});
</script>